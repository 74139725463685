// Initialize first
@import '../init';

// Style Here
.jobs-v2 {
  // Section Hero
  .hero {
    .container {
      padding-top: toRem(166);
      padding-bottom: toRem(80);
      display: flex;
      flex-direction: column;
      gap: toRem(48);
      align-items: center;

      @include media-breakpoint-down(md) {
        padding: toRem(64) 0;
        padding-top: toRem(136);
      }

      @include media-breakpoint-down(sm) {
        gap: toRem(56);
      }
    }

    &__header {
      text-align: center;
      max-width: 615px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: toRem(24);

      @include media-breakpoint-down(sm) {
        gap: toRem(16);
        padding-inline: toRem(20);
      }

      h1 {
        color: $color-white;
      }

      p {
        color: $color-lighter-grey;
        max-width: 467px;
      }
    }

    &__img {
      width: 100%;
      height: auto;
      border-radius: 10px;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        border-radius: unset;
      }

      img {
        width: 100%;
        aspect-ratio: 2.2 / 1;

        @include media-breakpoint-down(sm) {
          width: 110%;
          height: 110%;
          margin-bottom: toRem(64);
        }
      }
    }
  }

  //   Section Jobs
  .jobs {
    position: relative;
    z-index: 1;

    // Pattern Settings
    .pattern {
      position: absolute;
      width: 1714.5px;
      height: 816px;
      background-image: url('../../media/images/patterns-and-ornaments/jobs-v2-pattern.svg');
      background-size: contain;
      background-position: center;
      left: toRem(-58);
      top: toRem(98);
      z-index: -1;
    }

    .container {
      padding: toRem(80) 0;
      display: flex;
      flex-direction: column;
      gap: toRem(64);
      align-items: center;

      @include media-breakpoint-down(md) {
        padding: toRem(64) 0;
      }

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
        gap: toRem(56);
      }

      h2 {
        color: $color-dark-deep-blue;
        text-align: center;
      }

      .jobs-wrap {
        display: flex;
        flex-direction: row;
        gap: toRem(32);
        width: 100%;

        @include media-breakpoint-down(lg) {
          flex-direction: column;
          gap: toRem(48);
        }

        .filter {
          width: calc(334 / 1100 * 100%);
          display: flex;
          flex-direction: column;
          gap: toRem(24);

          @include media-breakpoint-down(lg) {
            width: 100%;
          }

          .filter-card {
            padding: toRem(24);
            display: flex;
            flex-direction: column;
            gap: toRem(24);
            border: solid 1px $color-lighter-grey;
            border-radius: 10px;
            background-color: $color-white;

            &:not(:first-child) {
              @include media-breakpoint-down(lg) {
                display: none;
              }
            }

            .text-l-bold {
              color: $color-black;
            }

            .checkboxes {
              display: flex;
              flex-direction: column;
              gap: toRem(16);
              color: $color-black;
              align-items: flex-start;
            }

            &--search {
              @include media-breakpoint-down(lg) {
                padding: 0;
                border: unset;
              }

              p {
                @include media-breakpoint-down(lg) {
                  display: none;
                }
              }

              .filter-button {
                width: 24px;
                height: 24px;
                background-image: url('../../media/images/icons/filter-alt-purple.svg');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;

                @include media-breakpoint-up(lg) {
                  display: none;
                }

                &:hover {
                  background-image: url('../../media/images/icons/filter-alt-dark-purple.svg');
                }
              }
            }
          }
        }

        .job-list {
          width: calc(734 / 1100 * 100%);
          display: flex;
          flex-direction: column;
          gap: 24px;

          @include media-breakpoint-down(lg) {
            width: 100%;
          }

          .job-card {
            background-color: $color-white;
            box-shadow: $shadow-sm;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: toRem(24) toRem(28);

            @include media-breakpoint-down(sm) {
              flex-direction: column;
              gap: toRem(16);
              padding: toRem(20) toRem(16);
              align-items: flex-start;
            }

            .text-job-wrap {
              display: flex;
              flex-direction: column;
              gap: toRem(12);

              @include media-breakpoint-down(sm) {
                gap: toRem(8);
              }

              .text-l-bold {
                color: $color-black;
              }

              .text-job {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: toRem(4);

                .text-m-medium {
                  color: $color-deep-blue;
                }

                .text-m-regular {
                  color: $color-grey;
                }

                .dot {
                  width: 2px;
                  height: 2px;
                  border-radius: 100%;
                  background-color: $color-black;
                }
              }
            }

            .info-job {
              display: flex;
              flex-direction: column;
              gap: toRem(12);
              align-items: flex-end;

              @include media-breakpoint-down(sm) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
              }
              .location {
                display: flex;
                flex-direction: row;
                gap: toRem(4);
                align-items: center;

                .icon-loc {
                  width: 20px;
                  height: 20px;
                  background-image: url('../../media/images/icons/location-logo.svg');
                  background-size: cover;
                  background-position: center;
                  background-repeat: no-repeat;
                }

                p {
                  color: $color-grey;
                }
              }

              p {
                color: $color-light-grey;
              }

              .text-desktop {
                @include media-breakpoint-down(md) {
                  display: none;
                }
              }

              .text-tab {
                @include media-breakpoint-up(md) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  //   Custom Button
  //   Button Show More
  .btn--show-more {
    padding: 0;
    color: $color-black;
    gap: toRem(12);

    .icon-arr-down {
      width: 24px;
      height: 24px;
      background-image: url('../../media/images/icons/arrow-ios-down-black.svg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:hover {
      color: $color-grey;

      .icon-arr-down {
        background-image: url('../../media/images/icons/arrow-ios-down-grey.svg');
      }
    }

    // When Active
    &.active {
      .icon-arr-down {
        transform: rotate(180deg);
      }
    }
  }

  //   Bottom Sheet Styling
  .sheet-filter {
    .downupPopup-content {
      .filter-sheet {
        padding: toRem(24) toRem(20);
        display: flex;
        flex-direction: column;
        gap: toRem(24);

        .checkboxes {
          display: flex;
          flex-direction: column;
          gap: toRem(16);
          align-items: flex-start;
        }
      }
    }
  }
}
